<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container">
      <div class="weui-tab">
        <div class="weui-tab__bd">
          <div
            id="tab1"
            class="
              weui-tab__bd-item weui-tab__bd-item--active
              public-task-surveys
            "
          >
            <div
              class="
                public-areas public-area-tasks public-area-tasks-index
                margin-bottom-10
              "
            >
              <div class="weui-cells">
                <!-- <div class="weui-cell weui-cell_access">
                  <div class="weui-cell__bd public-index-block-title">
                    <p>
                      秒抢 <span class="title-icon bg-red">抢</span
                      ><span class="title-icon bg-red">先</span
                      ><span class="title-icon bg-red">赚</span>
                    </p>
                  </div>
                </div> -->
                <div class="weui-cell weui-cell-7-5">
                  <div class="weui-cell__bd">
                    <div class="weui-flex">
                      <div class="recommend-table">
                        <div class="text-center margin-top-10 remark">
                          <span class="public-empty-icon-bg"
                            ><i class="icon icon-public-empty-box3"></i
                          ></span>
                        </div>
                        <div class="text-center margin-top-10 text-red light">
                          问卷越早来做越容易成功<br />加QQ群：835535251，获取问卷通知及问卷配额
                        </div>
                        <div class="text-center margin-top-10 margin-bottom-10">
                          <a
                            href="javascript:;"
                            @click="showQrcodeDialog"
                            class="
                              margin-top-10
                              btn-attention-mini
                              public-btn-activity
                              public-btn-invite
                              public-btn-invite-type
                            "
                            >复制群号</a
                          >
                          <!-- <a
                            href="https://qm.qq.com/cgi-bin/qm/qr?k=YAKxTOhNZdvNPQZRnUH_9NFZnffXj7vQ&jump_from=webapi&authKey=4OKBjQK15HBpeGY3Ek/q7nFGJw8PtEWiy63nwCn3JCUiogBt1oxq+HqrfFwhljAt"
                            class="
                              margin-top-10
                              btn-attention-mini
                              public-btn-activity
                              public-btn-invite
                              public-btn-invite-type
                            "
                            >联系客服</a
                          > -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                 public-area-tasks public-area-tasks-index
                margin-bottom-10
              "
            >
              <div class="weui-cells">
                <div class="weui-cell weui-cell_access public-areas">
                  <div class="weui-cell__bd public-index-block-title">
                    <p>
                      今日主推 <span class="title-icon bg-red">简</span
                      ><span class="title-icon bg-red">单</span
                      ><span class="title-icon bg-red">好</span
                      ><span class="title-icon bg-red">答</span>
                    </p>
                    <div class="hr"></div>
                  </div>
                </div>
                <!-- <div class="weui-cell weui-cell-7-5">
                  <div class="weui-cell__bd">
                    <div class="weui-flex">
                      <div
                        class="
                          weui-flex__item
                          recommend
                          survey-list-item survey-list-item-home
                          public-area-tasks-item-list
                        "
                        href="javascript:;"
                        @click="$router.push({ name: 'surveyDetail', query: {recordIdentifier: item.recordIdentifier} })"
                        v-for="(item, index) in RecordList" :key="index"
                      >
                        <div class="img">
                          <img
                            src="https://www.diaoyanhome.com/image/storage/upload/cover/11.jpeg@w200_h100"
                          />
                        </div>
                        <div class="title">{{item.title}}</div>
                        <div class="remark">
                          奖励：<span class="text-red"
                            >{{item.completeBonus}}
                            <i
                              class="icon icon-public-coins coin text-red"
                            ></i></span
                          ><span
                            style="float: right; margin-top: 3px"
                            class="remark"
                            >已答：{{item.answeredCount}}人</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="public-task-surveys-list">
                  <div class="public-areas public-area-tasks margin-bottom-10" v-for="(item, index) in RecordList" :key="index">
                    <div class="weui-cells">
                      <a
                        class="
                          weui-cell weui-cell_access
                          public-area-tasks-item
                          survey-list-item
                          public-area-tasks-item-list
                        "
                        href="javascript:;"
                        @click="$router.push({ name: 'surveyDetail', query: {recordIdentifier: item.recordIdentifier} })"
                      >
                        <div class="weui-cell__hd">
                          <img width="80"
                            :src="item.imageUrl"
                          />
                        </div>
                        <div class="weui-cell__bd">
                          <p class="title">
                            {{item.title}}
                            <span class="layui-badge" v-if="item.pcDedicated === 'Y'">仅电脑</span>
                            <span class="layui-badge" v-for="(itemIn, indexIn) in item.tags" :key="indexIn">{{itemIn}}</span>
                            <i
                              class="icon icon-hot-inline icon-public-hot-fire hot"
                              v-if="item.recommended === 'Y'"
                            ></i>
                          </p>
                          <p class="remark">
                            <span class="text-blue"
                              >{{item.completeBonus}}
                              <i class="icon icon-public-coins coin text-blue"></i
                            ></span>
                          </p>
                          <p class="remark infoList">
                            <span>问卷ID：{{item.id}}</span>
                            <span>发布时间：{{item.createTime | timeFormat}}</span>
                            <span>成功人数：{{ item.successCount }}</span>
                            <span>冻结比例：{{ item.frozenPercentage | percentFormat }}&nbsp;<i @click.stop="showTip" class="icon icon-public-help" /></span>
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="public-task-surveys-list">
              <div class="public-areas public-area-tasks margin-bottom-10" v-for="(item, index) in normalList" :key="index">
                <div class="weui-cells">
                  <div class="weui-cell weui-cell_access" v-if="index === 0">
                    <div class="weui-cell__bd public-index-block-title">
                      <p>日常问卷</p>
                    </div>
                  </div>
                  <a
                    class="
                      weui-cell weui-cell_access
                      public-area-tasks-item
                      survey-list-item
                      public-area-tasks-item-list
                    "
                    href="javascript:;"
                    @click="$router.push({ name: 'surveyDetail', query: {recordIdentifier: item.recordIdentifier} })"
                  >
                    <div class="weui-cell__hd">
                      <img width="80"
                        :src="item.imageUrl"
                      />
                    </div>
                    <div class="weui-cell__bd">
                      <p class="title">
                        {{item.title}}
                        <span class="layui-badge" v-if="item.pcDedicated === 'Y'">仅电脑</span>
                        <span class="layui-badge" v-for="(itemIn, indexIn) in item.tags" :key="indexIn">{{itemIn}}</span>
                        <i
                          class="icon icon-hot-inline icon-public-hot-fire hot"
                          v-if="item.recommended === 'Y'"
                        ></i>
                      </p>
                      <p class="remark">
                        <span class="text-blue"
                          >{{item.completeBonus}}
                          <i class="icon icon-public-coins coin text-blue"></i
                        ></span>
                      </p>
                      <p class="remark infoList">
                        <span>问卷ID：{{item.id}}</span>
                        <span>发布时间：{{item.createTime | timeFormat}}</span>
                        <span>成功人数：{{ item.successCount }}</span>
                        <span>冻结比例：{{ item.frozenPercentage | percentFormat }}&nbsp;<i @click.stop="showTip" class="icon icon-public-help" /></span>
                      </p>
                    </div>
                  </a>
                </div>
              </div>

              <!-- <div class="public-areas public-area-tasks margin-bottom-10">
                <div class="weui-cells">
                  <div class="weui-cell weui-cell_access">
                    <div class="weui-cell__bd public-index-block-title">
                      <p>日常问卷</p>
                    </div>
                  </div>
                  <a
                    class="
                      weui-cell weui-cell_access
                      public-area-tasks-item
                      survey-list-item
                      public-area-tasks-item-list
                    "
                    href="javascript:;"
                    @click="$router.push({ name: 'surveyDetail' })"
                  >
                    <div class="weui-cell__hd">
                      <img
                        src="https://www.diaoyanhome.com/image/storage/upload/cover/8.jpeg@s80"
                      />
                    </div>
                    <div class="weui-cell__bd">
                      <p class="title">
                        DY-婚戒品牌调查（海外问卷加载较慢，酌情参与）
                        <i
                          class="icon icon-hot-inline icon-public-hot-fire hot"
                        ></i>
                      </p>
                      <p class="remark">
                        <span class="text-blue"
                          >200
                          <i class="icon icon-public-coins coin text-blue"></i
                        ></span>
                      </p>
                      <p class="remark">
                        问卷ID：25692&nbsp;&nbsp;&nbsp;&nbsp;发布时间：2022-03-15
                      </p>
                    </div>
                  </a>
                </div>
              </div>
              <div class="public-areas public-area-tasks margin-bottom-10">
                <div class="weui-cells">
                  <a
                    class="
                      weui-cell weui-cell_access
                      public-area-tasks-item
                      survey-list-item
                      public-area-tasks-item-list
                    "
                    href="javascript:;"
                    @click="$router.push({ name: 'surveyDetail' })"
                  >
                    <div class="weui-cell__hd">
                      <img
                        src="https://www.diaoyanhome.com/image/storage/upload/cover/14.jpeg@s80"
                      />
                    </div>
                    <div class="weui-cell__bd">
                      <p class="title">
                        DY-在线调查
                        <i
                          class="icon icon-hot-inline icon-public-hot-fire hot"
                        ></i>
                      </p>
                      <p class="remark">
                        <span class="text-blue"
                          >300
                          <i class="icon icon-public-coins coin text-blue"></i
                        ></span>
                      </p>
                      <p class="remark">
                        问卷ID：25695&nbsp;&nbsp;&nbsp;&nbsp;发布时间：2022-03-15
                      </p>
                    </div>
                  </a>
                </div>
              </div>
              <div class="public-areas public-area-tasks margin-bottom-10">
                <div class="weui-cells">
                  <a
                    class="
                      weui-cell weui-cell_access
                      public-area-tasks-item
                      survey-list-item
                      public-area-tasks-item-list
                    "
                    href="javascript:;"
                    @click="$router.push({ name: 'surveyDetail' })"
                  >
                    <div class="weui-cell__hd">
                      <img
                        src="https://www.diaoyanhome.com/image/storage/upload/cover/13.jpeg@s80"
                      />
                    </div>
                    <div class="weui-cell__bd">
                      <p class="title">
                        WJ2-酒类调查-仅限电脑答题
                        <span class="layui-badge">仅电脑</span>
                      </p>
                      <p class="remark">
                        <span class="text-blue"
                          >300
                          <i class="icon icon-public-coins coin text-blue"></i
                        ></span>
                      </p>
                      <p class="remark">
                        问卷ID：25657&nbsp;&nbsp;&nbsp;&nbsp;发布时间：2022-03-14
                      </p>
                    </div>
                  </a>
                </div>
              </div>
              <div class="public-areas public-area-tasks margin-bottom-10">
                <div class="weui-cells">
                  <a
                    class="
                      weui-cell weui-cell_access
                      public-area-tasks-item
                      survey-list-item
                      public-area-tasks-item-list
                    "
                    href="javascript:;"
                    @click="$router.push({ name: 'surveyDetail' })"
                  >
                    <div class="weui-cell__hd">
                      <img
                        src="https://www.diaoyanhome.com/image/storage/upload/cover/13.jpeg@s80"
                      />
                    </div>
                    <div class="weui-cell__bd">
                      <p class="title">
                        DY-个人护理-成人（海外问卷进入较慢，酌情参与）
                      </p>
                      <p class="remark">
                        <span class="text-blue"
                          >300
                          <i class="icon icon-public-coins coin text-blue"></i
                        ></span>
                      </p>
                      <p class="remark">
                        问卷ID：25560&nbsp;&nbsp;&nbsp;&nbsp;发布时间：2022-03-09
                      </p>
                    </div>
                  </a>
                </div>
              </div>
              <div class="public-areas public-area-tasks margin-bottom-10">
                <div class="weui-cells">
                  <a
                    class="
                      weui-cell weui-cell_access
                      public-area-tasks-item
                      survey-list-item
                      public-area-tasks-item-list
                    "
                    href="javascript:;"
                    @click="$router.push({ name: 'surveyDetail' })"
                  >
                    <div class="weui-cell__hd">
                      <img
                        src="https://www.diaoyanhome.com/image/storage/upload/cover/13.jpeg@s80"
                      />
                    </div>
                    <div class="weui-cell__bd">
                      <p class="title">WJ2-Y30685566</p>
                      <p class="remark">
                        <span class="text-blue"
                          >300
                          <i class="icon icon-public-coins coin text-blue"></i
                        ></span>
                      </p>
                      <p class="remark">
                        问卷ID：25510&nbsp;&nbsp;&nbsp;&nbsp;发布时间：{{'20220430' | timeFormat}}
                      </p>
                    </div>
                  </a>
                </div>
              </div>
              <div class="public-areas public-area-tasks margin-bottom-10">
                <div class="weui-cells">
                  <a
                    class="
                      weui-cell weui-cell_access
                      public-area-tasks-item
                      survey-list-item
                      public-area-tasks-item-list
                    "
                    href="javascript:;"
                    @click="$router.push({ name: 'surveyDetail' })"
                  >
                    <div class="weui-cell__hd">
                      <img
                        src="https://www.diaoyanhome.com/image/storage/upload/cover/4.jpeg@s80"
                      />
                    </div>
                    <div class="weui-cell__bd">
                      <p class="title">DY-在线调查</p>
                      <p class="remark">
                        <span class="text-blue"
                          >300
                          <i class="icon icon-public-coins coin text-blue"></i
                        ></span>
                      </p>
                      <p class="remark">
                        问卷ID：25232&nbsp;&nbsp;&nbsp;&nbsp;发布时间：2022-02-19
                      </p>
                    </div>
                  </a>
                </div>
              </div> -->
            </div>
            <div class="weui-loadmore public-loadmore hidden">
              <i class="weui-loading"></i>
              <span class="weui-loadmore__tips">正在加载</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <tabbar :selectedVal="'home'"></tabbar>
  </div>
</template>

<script>
import copy from 'copy-to-clipboard'
import throttle from 'lodash/throttle'
import { getQList } from '../api/index'
// import { getParams } from '../utils/index'
import tabbar from '../components/tabbar/index.vue'
import wxauth from '../mixin/wxauth'
export default {
  mixins: [wxauth],
  name: "home",
  components: {
    tabbar
  },
  data () {
    return {
      pageNum: 1,
      pageSize: 20,
      pageCount: 1, //	总共页数
      RecordList: [],
      normalList: [],
      isWX: false
    }
  },
  watch: {
    pageNum: function () {
      this.doGetQList()
    }
  },
  created () {
    const ua = navigator.userAgent.toLowerCase()
    if(ua.match(/MicroMessenger/i)=="micromessenger") {
      this.isWX = true
    }
  },
  mounted () {
  },
  methods: {
    doWxAuthCb () {
      this.doGetQList()
      this.doGetQList('Y')
      this.listenScroll()
    },
    // 提交用户信息
    async doGetQList (recommended = 'N') {
      let params = {
        param: {
          pageNum: this.pageNum,
          pageSize: recommended === 'N' ? this.pageSize : 10,
          recommended,
        }
      }
      let res = {}
      try {
        res = await getQList(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          const result = res.result
          const list = result.questionnaireRecordList
          if (recommended === 'Y') { // 主推列表
            this.RecordList = list
          } else {
            this.normalList.push(...list)
            this.pageCount = result.pageCount
          }
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    showTip() {
      let content = "";
      content += '<div class="text-left" >';
      content +=
        '<p class="margin-bottom-10">因部分用户答题过程中乱答乱写，严重影响数据质量。为了保证作答有效，部分问卷作答成功获得奖励后，将暂时冻结部分成功奖励等待质量审核，等待审核期间这部分金额不可提现。具体规则如下：</p>';
      content +=
        '<p class="margin-bottom-10">1、如果审核不合格，将从账户余额中扣除本次作答的全额奖励</p>';
      content +=
        '<p class="margin-bottom-10">2、如果审核通过，冻结的金币将立即取消冻结、恢复为可提现状态</p><br>';
      content +=
        '<p class="margin-bottom-10 text-red">温馨提示：为了大家的利益不受损失，请大家认真作答、切勿乱答乱写哦~</p>';

      content += "</div>";
      this.$dialog.show({
        title: "为何有部分余额暂时不能提现",
        content: content,
        showCancel: false,
        okText: "确定",
      });
    },
    showQrcodeDialog() {
      copy('835535251')
      this.$toast.info('QQ群号已复制，请到QQ内加入群聊')
      // if (this.isWX) {
      //   let content = '<img style="width:60%;" src="./assets/img/qq_qun_qr.jpg"><div class="padding-15">长按识别二维码，加入QQ群聊</div>';
      //   this.$dialog.show({
      //     title: "每日调查任务",
      //     content: content,
      //     showCancel: false,
      //     okText: "确定",
      //   });
      // } else {
      //   const url = 'https://qm.qq.com/cgi-bin/qm/qr?k=YAKxTOhNZdvNPQZRnUH_9NFZnffXj7vQ&jump_from=webapi&authKey=4OKBjQK15HBpeGY3Ek/q7nFGJw8PtEWiy63nwCn3JCUiogBt1oxq+HqrfFwhljAt'
      //   location.href = url
      // }
    },
    // 页面已经滑动到底部
    pageToBottom () {
      if (this.pageNum < this.pageCount) {
        this.pageNum++
      }
    },
    listenScroll () {
      const _this = this
      window.onscroll = throttle(function (e) {
        console.log('e:', e)
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        let screenH = document.documentElement?.clientHeight || window.innerHeight
        let bodyH = document.body.scrollHeight
        if ((scrollTop + screenH + 10 - bodyH) > 0 ) {
          console.log('页面已经滑动到底部')
          _this.pageToBottom()
        }
      }, 500)
    }
  },
};
</script>
<style lang="css" scoped>
.public-container{
  margin: unset;
}
.public-index-block-title>.hr{
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
}
.remark.infoList{
  display: flex;
  flex-wrap: wrap;
}
.remark.infoList>span{
  display: flex;
  flex-shrink: 0;
  font-size: 0.512rem;
  margin-top: 0.1rem;
  align-items: center;
}
.remark.infoList>span:nth-of-type(2n+1){
  width: 40%;
}
.remark.infoList>span:nth-of-type(2n){
  width: 60%;
}
</style>
